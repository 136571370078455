var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-form-fecha"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Actualizar Fecha Vencimiento")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha vencimiento")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_vencimiento),expression:"form.fecha_vencimiento"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha_vencimiento.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"type":"date"},domProps:{"value":(_vm.form.fecha_vencimiento)},on:{"change":function($event){return _vm.validarFecha()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_vencimiento", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"hora_fin"}},[_vm._v("Hora vencimiento")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hora_vencimiento),expression:"form.hora_vencimiento"},{name:"mask",rawName:"v-mask",value:({
                  mask: '99:99',
                  hourFormat: '24',
                }),expression:"{\n                  mask: '99:99',\n                  hourFormat: '24',\n                }"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.hora_vencimiento.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.form.hora_vencimiento)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "hora_vencimiento", $event.target.value)}}})])])]),(_vm.inspeccion.fecha_vencimiento < _vm.hoy)?_c('div',{staticClass:"col-md-11 pl-4 d-flex align-items-stretch flex-column"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form.$invalid),expression:"!$v.form.$invalid"}],staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"small-box alert-default-warning",staticStyle:{"min-height":"100px"}},[_c('div',{staticClass:"inner"},[_c('h5',[_c('strong',[_vm._v("La inspección ya se encuentra vencida")])])]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"far fa-calendar-plus"})])])
}]

export { render, staticRenderFns }